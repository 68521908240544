<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height: 600px">
      <div class="title">生成二维码</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="100px">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="设备编号:">
                <el-input
                  style="width: 100%"
                  v-model="device_id"
                  placeholder="请输入设备编号"
                ></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="1">
              <div
                style="margin-left: 60px"
                class="BtnSearch"
                @click="showerwm()"
              >
                查询
              </div>
            </el-col>
          </el-row>
        </el-form>
        <!-- <div class="btnDiv" style="width: 100%;">
            <el-button style="margin: 0 auto;" @click="submit">提交</el-button>
          </div> -->

        <!-- 二维码 -->
        <div
          style="
            display: flex;
            justify-content: space-evenly;
            width: 680px;
            text-align: center;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          "
        >
        
          <div id="qrcode" style="margin-top: 10px" ref="qrCodeUrl"></div>
          <div v-if="isDeviceIdVisible" class="device_id" 
          style="
            font-size: 18px;
            display: flex;
            justify-content: space-evenly;
            text-align:center;
            margin-top: 10px;
            width: 200px;">
            {{ device_id }}
            </div>
          <!-- <p style="margin-top:4px; font-size: 18px;">{{ Dialog1.DialogData.device_id }}</p> -->
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { refs, reactive, toRefs } from "vue-demi";
import QRCode from "qrcodejs2";
import { ElMessageBox } from "element-plus";
//   import { ElMessage } from "element-plus";
//   import {  regionData } from 'element-china-area-data'

export default {
  name: "SearchMan",
  data() {
    return {
      device_id: "",
      isDeviceIdVisible: false
    };
  },
  methods: {
    showerwm() {
      document.getElementById("qrcode").innerHTML = "";
      console.log("点击显示二维码！");
      this.isDeviceIdVisible = true; 
      if (this.device_id.length == 14) {
        setTimeout(() => {
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: `http://materials-china.com?No=${this.device_id}01`, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }, 1);
      } else if (this.device_id.length == 8) {
        setTimeout(() => {
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: `http://materials-china.com?No=${this.device_id}`, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }, 1);
      } else {
        ElMessageBox.confirm("请输入8位或14位设备编码！", "警告", {
          type: "info",
          confirmButtonText: "确认",
          cancelButtonText: "取消",
        });
      }
    },
    //   const delerwm = () =>{
    // this.Dialog1.editLevy = false
    // document.getElementById("qrcode").innerHTML = ""
  },
  //
};
</script>
  